<template>
  <div style="">
    <!-- 优惠券详情 -->
    <van-nav-bar
      class="backBox"
      title="我的优惠券"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="page">
      <div class="box_2">
        <div class="box_3">
          <img src="../../../assets/img/parkP@2x.png" class="icon_2" />
          <span class="text-group_1">银湖停车场</span>
        </div>
        <span class="text_10" v-if="detailInfo.type == 1">金额减免券</span>
        <span class="text_10" v-if="detailInfo.type == 2">时长减免券</span>
        <span class="text_10" v-if="detailInfo.type == 3">折扣减免券</span>
        <span class="text_10" v-if="detailInfo.type == 4">全免券</span>

        <!-- 未使用 -->
        <!-- <div class="aliveCoupon" v-if="{{detailInfo.status==1}}"> -->
        <div class="aliveCoupon">
          <div v-if="detailType == 1">
            <!-- <span  class="text_10">折扣减免券</span> -->
            <div class="text-wrapper_6">
              <span class="text_12">领券时间</span>
              <span class="text_13">{{timeFormatter(detailInfo.granted_time) }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_12">有效期截止时间</span>
              <span class="text_13">{{ judgeEndDateLocal(detailInfo.e_e_time) }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_12">允许使用开始时间</span>
              <span class="text_13">{{ detailInfo.allow_s_time }}</span>
            </div>

            <div class="text-wrapper_6">
              <span class="text_12">允许使用结束时间</span>
              <span class="text_13">{{ detailInfo.allow_e_time }}</span>
            </div>
          </div>
          <div class="" v-if="detailType == 2">
            <!-- 使用显示状态 -->
            <div class="text-wrapper_6">
              <span class="text_12">状态</span>
              <span class="text_13" v-if="detailInfo.status == 2">已使用</span>
              <span
                class="text_13"
                v-if="detailInfo.status == 4 || detailInfo.status == 3"
                >已过期</span
              >
            </div>
            <!-- 已使用 -->
            <div class="text-wrapper_6" v-if="detailInfo.status == 2">
              <span class="text_12">使用时间</span>
              <span class="text_13">{{
                formatDate(detailInfo.latest_used_time)
              }}</span>
            </div>
            <!-- 已过期 -->
            <div
              class="text-wrapper_6"
              v-if="detailInfo.status == 4 || detailInfo.status == 3"
            >
              <span class="text_12">过期时间</span>
              <span class="text_13">{{
                formatDate(detailInfo.expire_time)
              }}</span>
            </div>
          </div>
        </div>

        <div class="outofdateCoupon" v-if="detailInfo.status == 3"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as common from '../../../api/common'
import * as util from '../../../api/util'
export default {
  props: ['type', 'item', 'tab'],
  data () {
    return {
      detailInfo: {},
      detailType: null,
      tabIndex: null
    }
  },
  mounted () {
    this.detailInfo = JSON.parse(decodeURIComponent(this.item))
    this.detailType = Number(this.type)
    this.tabIndex = this.tab
    console.log('Type:', this.detailType)
    console.log('Item:', this.detailInfo)
    console.log('tab:', this.tabIndex)
  },
  computed: {

  },
  methods: {
    judgeEndDateLocal (date) {
      if (util.judgeEndDateLong(date)) {
        return '长期有效'
      } else {
        return common.format(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      }
    },
    timeFormatter (para) {
      return common.format(new Date(para), 'yyyy-MM-dd hh:mm:ss')
    },
    formatDate (date) {
      return date ? common.timeFormat(date, 'yyyy-MM-dd hh:mm:ss') : ''
    },
    onClickLeft () {
      // this.$router.go(-1)
      this.$router.replace({ path: '/myCoupon', query: { tab: this.tabIndex } })
    }

  }
}
</script>
<style lang="scss" scoped>
.page {
  // height: calc(100vh - 50px);
}
.box_2 {
  background-color: rgba(255, 255, 255, 1);
  /* width: 686rpx; */
  align-self: center;
  margin-top: 0.16rem;
  display: flex;
  flex-direction: column;
  padding: 0.1rem;
  .box_3 {
    width: 0.81rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin: 0 1.27rem 0 1.15rem;
    .icon_2 {
      width: 0.2rem;
      height: 0.2rem;
    }
    .text-group_1 {
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 0.11rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.16rem;
      margin-top: 0.02rem;
    }
  }
  .text_10 {
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 0.14rem;
    font-family: PingFangSC-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 0.2rem;
    align-self: center;
    margin-top: 0.06rem;
  }
  .text-wrapper_6 {
    width: 3.23rem;
    margin-top: 0.16rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .text_12 {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
    .text_13 {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }
}
</style>
